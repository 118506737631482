<template>
  <div>
    <v-form v-model="mainFormValid" class="pa-4">
      <v-text-field
        v-model="internalValue.name"
        :rules="$options.rules.name"
        label="Nom"
        type="text"
        @input="change"
      />
      <v-autocomplete
        v-model="internalValue.operationTypeList"
        :rules="$options.rules.operationTypeList"
        label="Liste des types d'opération"
        :items="operationTypeList"
        item-text="name"
        item-value="_id"
        :item-disabled="disableItem"
        multiple
        chips
        deletable-chips
        @input="change"
      />

      <v-autocomplete
        v-model="internalValue.rejectionCauseList"
        label="Liste des causes de rebuts"
        :items="rejectionCauseList"
        item-text="name"
        item-value="_id"
        multiple
        chips
        deletable-chips
        @input="change"
      />
    </v-form>
  </div>
</template>

<script>
import Repositories from 'src/repositories'
import Helpers from 'src/utils/helpers'
import FormRules from 'src/utils/form-rules'

export default {
  name: 'OperationCategoryForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    internalValue: null,
    mainFormValid: false,
    operationTypeList: [],
    rejectionCauseList: [],
  }),
  computed: {
    isValidOperationCategory () {
      return this.mainFormValid
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = Helpers.clone(value)
        this.$emit('validate', this.isValidOperationCategory)
      },
    },
    isValidOperationCategory: {
      immediate: true,
      handler () {
        this.$emit('validate', this.isValidOperationCategory)
      },
    },
  },
  async mounted () {
    const [operationTypeList, rejectionCauseList] = await Promise.all([
      await Repositories.operationType.getList({
        archived: false,
      }),
      await Repositories.rejectionCause.getList({
        archived: false,
      }),
    ])

    this.operationTypeList = operationTypeList
    this.rejectionCauseList = rejectionCauseList
  },
  methods: {
    change () {
      this.$emit('input', this.internalValue)
      this.$emit('validate', this.isValidOperationCategory)
    },
    disableItem (item) {
      if (this.internalValue._id) {
        return item.category && item.category._id !== this.internalValue._id
      }
      return item.category
    },
  },
  rules: {
    name: [
      FormRules.required('requis'),
    ],
    operationTypeList: [
      FormRules.required('requis'),
    ],
  },
}
</script>
